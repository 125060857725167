
  .row-desh {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  
  button-deh {
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 40px;
    width: 120px;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    left: 90px;
    top: -7px;
  }
  